import React from 'react'
import { Outlet } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'
import { getVisitors_act } from '../../redux/ducks/visitors/actions' 
import { connect } from 'react-redux'

const YearConfig = (props) => {
  const navigate = useNavigate()
  const { yearconfig } = useParams()
  React.useEffect(() => {
      const years = ["2022","2023","2024","2025"]
      if(!years.includes(yearconfig)){
          navigate('/')
      }
  },[yearconfig, navigate])

  React.useEffect(() => { props.getVisitors() },[])

  React.useEffect(() => {
      const intervalId = setInterval(() => {
          props.getVisitors()
      }, (180 * 1000))

      // Membersihkan interval saat komponen unmount
      return () => clearInterval(intervalId);
  },[])

  return (
    <Outlet />
  )
}

const mapStateToProps = (state) => ({
  visitors: state.visitorsState.visitors.data
})

const mapDispatchToProps = {
  getVisitors: getVisitors_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(YearConfig)