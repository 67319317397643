import * as types from "./types"

const getListOPDPerjanjianKinerja_act = () => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_LISTOPD_PK_START })
    const response = await Api.getListOPDPerjanjianKinerja()
    if (response.status !== 'failed'){
        dispatch({
            type: types.GET_LISTOPD_PK_SUCCESS,
            payload: {
                data: response.data.message,
                message: response.data.label
            }
        })
    }
    else{
        dispatch({
            type: types.GET_LISTOPD_PK_FAILED,
            payload: {
                message: response.error
            }
        })
    }
}

const getCapaianPerjanjianKinerja_act = (idopd, tahun = "") => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_CAPAIAN_PK_START })
    let tanggal = ""
    if(tahun == "2023"){
        tanggal = tahun+"-12-30"
    }
    else tanggal = tahun
    const response = await Api.getCapaianPerjanjianKinerja(idopd, tanggal)
    if (response.status !== "failed"){
        dispatch({
            type: types.GET_CAPAIAN_PK_SUCCESS,
            payload: {
                data: response.data.message,
                message: response.data.label
            }
        })
    }
    else{
        dispatch({
            type: types.GET_CAPAIAN_PK_FAILED,
            payload: {
                message: response.error
            }
        })
    }
}

export {
    getListOPDPerjanjianKinerja_act,
    getCapaianPerjanjianKinerja_act
}